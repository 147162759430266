import React, { FC, memo } from 'react'
import { TeaserProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import { TeaserSizeTypes } from '@/types/teaser'
import Teaser from '@/components/Teaser'

type Props = {
    teaser: TeaserProps[]
}

const TopVideoListTeaser: FC<Props> = ({ teaser }) => {
    const headlineText = `TOP ${teaser.length} VIDEOS`

    return (
        <NonFlexingContainer id="piano_top-video-list-teaser" testID="top-video-list-teaser">
            <NonFlexingContainer spaceBottom="standard">
                <CardHeadline cardHeadlineType="cardHeadlineS" text={headlineText} />
            </NonFlexingContainer>
            {teaser.map((teaserEntry, index) => {
                const isLastItem = index === teaser.length - 1
                return (
                    <NonFlexingContainer
                        key={`top-teaser-list-item-${teaserEntry.id}-${teaserEntry.componentKey}`}
                    >
                        <NonFlexingContainer
                            marginBottom={!isLastItem ? 'spacing-7' : 'spacing-none'}
                        >
                            <Teaser size={TeaserSizeTypes.SMALL} teaser={teaserEntry} hasBorder />
                        </NonFlexingContainer>
                    </NonFlexingContainer>
                )
            })}
        </NonFlexingContainer>
    )
}

export default memo(TopVideoListTeaser)
