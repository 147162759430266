import React, { FC, memo } from 'react'
import NewsTickerLine, { NewsTickerLineProps } from '@sport1/news-styleguide/NewsTickerLine'
import Link, { LinkProps } from 'next/link'
import useCurrentDeviceSize from '../../hooks/useCurrentDeviceSize'

type WrappedNewsTickerLineProps = NewsTickerLineProps & LinkProps

const WrappedNewsTickerLine: FC<WrappedNewsTickerLineProps> = ({ href, ...rest }) => {
    const { deviceSizeDesktop } = useCurrentDeviceSize()

    return (
        <Link href={href} passHref legacyBehavior>
            <NewsTickerLine {...rest} href={href} hasBorder={deviceSizeDesktop} />
        </Link>
    )
}

export default memo(WrappedNewsTickerLine)
